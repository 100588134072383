<template>
  <div class="contractchargeset public-box">
    <el-form :inline="true">
      <el-form-item>
        <MyInput
          v-model="params.contract_fee_name"
          placeholder="输入收费项名称"
        >
          <template slot="pre"> 收费项名称: </template>
        </MyInput>
      </el-form-item>
      <el-form-item>
        <MyButton title="搜索" @click="getList">
          <template slot="preImage">
            <img src="@/unit/img/search.png" alt="" />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton
          title="新增收费项"
          :accessId="16001"
          @click="dialogVisible = true"
        >
          <template slot="preImage">
            <img src="@/unit/img/zj.png" alt="" />
          </template>
        </MyButton>
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }"
      v-loading="loading"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column
        label="收费项名称"
        prop="contract_fee_name"
      ></el-table-column>
      <el-table-column label="收费方式" prop="type">
        <template slot-scope="scope">
          {{ scope.row.type == 10 ? "按月收费" : "固定收费" }}
        </template>
      </el-table-column>
      <el-table-column label="是否随基础费用缴纳">
        <template slot-scope="scope">
          {{ scope.row.rent_type == 10 ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="是否为多次收费">
        <template slot-scope="scope">
          {{ scope.row.repeated_type == 10 ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="是否必填">
        <template slot-scope="scope">
          {{ scope.row.required_type == 10 ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div style="width: 100px; margin: 0 auto; text-align: left">
            <pop tips="编辑" :accessId="16002" @myclick="edit(scope.row)">
              <img class="icon" src="../../../assets/img/icon/edit.png" alt />
            </pop>
            <!-- <el-switch v-model="scope.row.status"  :active-value="1"  :inactive-value="0"></el-switch> -->
            <pop
              tips="停用"
              :accessId="16005"
              popRight
              popLeft
              v-if="scope.row.status == 1"
              @myclick="cutStatus(scope.row, 0)"
            >
              <img
                style="width: 18px; height: 14px"
                src="../../../assets/img/icon/kai.png"
                alt
              />
            </pop>
            <pop
              tips="启用"
              :accessId="16003"
              popRight
              popLeft
              v-else
              @myclick="cutStatus(scope.row, 1)"
            >
              <img
                style="width: 18px; height: 14px"
                src="../../../assets/img/icon/guan.png"
                alt
              />
            </pop>
            <pop
              tips="删除"
              :accessId="16004"
              @myclick="del(scope.row)"
              v-if="scope.$index > 1"
            >
              <img
                class="icon"
                src="../../../assets/img/icon/deleted.png"
                alt
              />
            </pop>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="desc">
      <p>说明：</p>
      <p>
        1、新增收费项：这里新增的收费项会在编辑合同模板时选中后将在新增合同时展示
      </p>
      <p>
        2、收费方式：按月收费：费用金额按月进行计算，每个月都会收取费用，与商铺租金一同收费，统称为基础费用；固定收费：固定收费金额，只收取一次，可以自定义缴费时间
      </p>
      <p>3、是否必填：为合同信息上传或续签合同时该项内容是否为必填</p>
      <p>4、基础费用为除“固定收费”费用外的所有费用之和</p>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      title="新建/编辑收费项"
      width="880px"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="200px"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
      >
        <el-form-item label="收费项名称:" prop="contract_fee_name">
          <el-input
            class="public-input"
            v-model="ruleForm.contract_fee_name"
            placeholder="输入收费项名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="计费方式:">
          <el-select
            class="public-input"
            :disabled="true"
            v-model="ruleForm.type"
            @change="changeType"
            placeholder="请选择计费方式"
          >
            <el-option label="按月收费" :value="10"></el-option>
            <el-option label="一次性收费" :value="20"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否随基础费用缴纳:">
          <el-radio-group
            :disabled="true"
            v-model="ruleForm.rent_type"
            @change="changeRentType"
          >
            <el-radio :label="10">是</el-radio>
            <el-radio :label="20">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否为多次收费:">
          <el-radio-group :disabled="true" v-model="ruleForm.repeated_type">
            <el-radio :label="10">是</el-radio>
            <el-radio :label="20">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否必填:">
          <el-radio-group :disabled="true" v-model="ruleForm.required_type">
            <el-radio :label="10">是</el-radio>
            <el-radio :label="20">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <MyButton right @click="dialogVisible = false">取消</MyButton>
        <MyButton @click="confirm" type="primary">确认</MyButton>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "assistantContractChargeset",
  data() {
    return {
      params: {
        contract_fee_name: "",
        is_contract_vice: 10,
      },
      tableData: [],
      dialogVisible: false,
      ruleForm: {
        contract_fee_name: "",
        type: 10, //收费类型 10 月，20 固定
        rent_type: 20, //是否随租金缴纳 10 是，20不是
        repeated_type: 10, //是否为多次收费 10 是，20不是
        required_type: 10, //是否必填10 是，20不是
      },
      rules: {
        contract_fee_name: [
          { required: true, message: "请输入收费项名称", trigger: "blur" },
        ],
        type: [
          { required: true, message: "请选择计费方式", trigger: "change" },
        ],
        rent_type: [
          {
            required: true,
            message: "请选择是否属于基础费用",
            trigger: "change",
          },
        ],
        repeated_type: [
          {
            required: true,
            message: "请选择是否为多次收费",
            trigger: "change",
          },
        ],
        required_type: [
          { required: true, message: "请选择是否必填", trigger: "change" },
        ],
      },
      loading: false,
    };
  },

  created() {
    this.getList();
  },
  methods: {
    del(item) {
      this.$myconfirm("此操作将永久删除该收费项, 是否继续?", "提示").then(
        () => {
          this.$request
            .HttpPost("/contract_fee_setting/delete", {
              ids: [item.id],
            })
            .then((res) => {
              this.$common.notifySuccess(res.msg);
              this.getList();
            });
        }
      );
    },
    cutStatus(item, status) {
      this.$request
        .HttpPost("/contract_fee_setting/edit", {
          status,
          id: item.id,
        })
        .then((res) => {
          this.$common.notifySuccess(res.msg);
          this.getList();
        });
    },
    edit(item) {
      this.$request
        .HttpGet("/contract_fee_setting/edit", {
          id: item.id,
        })
        .then((res) => {
          this.ruleForm = res.data;
          if (res.data.type == 20) {
            this.changeType(res.data.type);
          }
          this.dialogVisible = true;
        });
    },
    changeType(e) {
      if (e == 20) {
        this.isRentType = true;
        this.ruleForm.rent_type = 20;
        this.ruleForm.refund_type = 10;
        this.ruleForm.repeated_type = 20;
      } else {
        this.isRentType = false;
        this.ruleForm.rent_type = 10;
        this.ruleForm.refund_type = 20;
        this.ruleForm.repeated_type = 10;
      }
    },
    // 却换是否属于基础费用
    changeRentType(e) {
      if (e == 20) {
        this.ruleForm.repeated_type = 20;
        this.ruleForm.refund_type = 10;
      } else {
        this.ruleForm.refund_type = 20;
        this.ruleForm.repeated_type = 10;
      }
    },
    close() {
      this.ruleForm.contract_fee_name = "";
      this.$refs.ruleForm.clearValidate("contract_fee_name");
    },
    confirm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let url = "";
          if (this.ruleForm.id) {
            url = "/contract_fee_setting/edit";
          } else {
            url = "/contract_fee_setting/add";
          }
          this.$request
            .HttpPost(url, {
              ...this.ruleForm,
              is_contract_vice: 10,
            })
            .then((res) => {
              this.$common.notifySuccess(res.msg);
              this.dialogVisible = false;
              this.getList();
            });
        }
      });
    },
    getList() {
      this.loading = true;
      this.$request
        .HttpPost("/contract_fee_setting/listAll", this.params)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data || [];
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.contractchargeset {
  .desc {
    margin-top: 20px;
    color: #e90000;
    font-size: 12px;
    p {
      margin-bottom: 5px;
    }
  }
}
</style>
